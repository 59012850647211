import React from "react"
import withLayout from "../../layout"
import SEO from "../../components/SEO";
import {injectIntl} from "react-intl";
import EventDetailTemplate from "../../components/EventDetailTemplate"
import eventsList from "../../utilities/webinars"
import LottieController from "../../components/UI/LottieController"
import EuroLottieIcon from "../../components/UI/Lottie/Euro.json"
import RocketLottieIcon from "../../components/UI/Lottie/Rocket.json"
import ValueLottieIcon from "../../components/UI/Lottie/Carrello.json"
import SalesLottieIcon from "../../components/UI/Lottie/sales.json"
import SimpleStats from "../../components/UI/SimpleStats"
import {useInView} from "react-intersection-observer"
import TestimonialImage from "../../images/agency/marco_ciotoli.jpg"

const SingleEventPageWrapper = ({intl}) => {
    const {
        name,
        link,
        title,
        date,
        times,
        price,
        image,
        imagesrc,
        id,
        canSubscribe
    } = eventsList.find(event => event.link === "come-l-utilizzo-dei-dati-puo-aumentare-le-performance-del-marketing-digitale")

    const templateData = {
        canSubscribe,
        name,
        link,
        title,
        testimonial: {
            image: TestimonialImage,
            name: "Marco Ciotoli",
            role: "Co-founder Kromin"
        },
        date,
        times,
        location: "events.online",
        price,
        image,
        imagesrc,
        id,
        description: "come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.description",
        list: [
            "come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.list_1",
            "come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.list_2",
            "come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.list_3",
            "come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.list_4"
        ],
        categories: [
            "events.marketing",
            "events.data"
        ],
        results: <Results/>,
        intl
    }
    const keywords = intl.formatMessage({id:"events.meta_keywords"}).split(",") || []

    return (
        <>
            <SEO
                title={intl.formatMessage({id: "events.meta_title"})}
                description={intl.formatMessage({id: "events.meta_description"})}
                keywords={keywords}
                lang={intl.locale}
            />

            <EventDetailTemplate
                {...templateData}
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "events", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(SingleEventPageWrapper))

const Results = () => {
    const [resultIcon1Ref, resultIcon1View] = useInView()
    const [resultIcon2Ref, resultIcon2View] = useInView()
    const [resultIcon3Ref, resultIcon3View] = useInView()
    const [resultIcon4Ref, resultIcon4View] = useInView()

    return (
        <div className="row">
            <div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                <div ref={resultIcon1Ref}>
                    <LottieController
                        lottieJSON={EuroLottieIcon}
                        inView={resultIcon1View}
                        width="120px"
                        height="120px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px!important"
                        prefix="-"
                        measureUnit="%"
                        inView={resultIcon1View}
                        valueFrom={0}
                        valueTo={20}
                        label="come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.results_1"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{
                            color: "#FFF",
                            margin: 0
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                <div ref={resultIcon2Ref}>
                    <LottieController
                        lottieJSON={RocketLottieIcon}
                        inView={resultIcon2View}
                        width="120px"
                        height="120px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px!important"
                        prefix="+"
                        measureUnit="%"
                        inView={resultIcon2View}
                        valueFrom={0}
                        valueTo={50}
                        label="come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.results_2"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{
                            color: "#FFF",
                            margin: 0
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                <div ref={resultIcon3Ref}>
                    <LottieController
                        lottieJSON={ValueLottieIcon}
                        inView={resultIcon3View}
                        width="120px"
                        height="120px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px!important"
                        prefix="+"
                        measureUnit="%"
                        inView={resultIcon3View}
                        valueFrom={0}
                        valueTo={36}
                        label="come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.results_3"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{
                            color: "#FFF",
                            margin: 0
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                <div ref={resultIcon4Ref}>
                    <LottieController
                        lottieJSON={SalesLottieIcon}
                        inView={resultIcon4View}
                        width="120px"
                        height="120px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px!important"
                        prefix="+"
                        measureUnit="%"
                        inView={resultIcon4View}
                        valueFrom={0}
                        valueTo={58}
                        label="come_l_utilizzo_dei_dati_puo_aumentare_le_performance_del_marketing_digitale.results_4"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{
                            color: "#FFF",
                            margin: 0
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
